window.dataLayer = window.dataLayer || [];

const PAGE_VIEW_EVENT = 'pageview';

const triggerOnKeyChange = (fn, keyFn = (...args) => args) => {
  let lastInvocation;

  return (...args) => {
    const thisInvocation = keyFn(...args);
    if (lastInvocation !== thisInvocation) {
      lastInvocation = thisInvocation;
      return fn(...args);
    }
  };
};

// Only trigger a page view when the last page view we tracked was a different page
export const trackPageView = triggerOnKeyChange(
  () => {
    window.dataLayer.push({
      event: PAGE_VIEW_EVENT,
      page: {
        path: window.location.pathname,
        title: document.title,
      },
    });
  },
  () => window.location.pathname
);
