import React from 'react';
import invariant from 'invariant';
import Markdown from 'markdown-to-jsx';
import InfoButton from '../components/InfoButton';
import ConsumeT from '../context/t';

const getMarkdownString = (t, key) => {
  return t(key, { defaultValue: '' }) || '';
};

function Text(props) {
  const {
    i18nKey,
    inline,
    className = '',
    markdown,
    componentOverrides = {},
  } = props;

  return (
    <ConsumeT>
      {t => {
        const overrides = {
          InfoButton: props => {
            const child = React.Children.toArray(props.children)[0];
            invariant(
              typeof child === 'string',
              `<InfoButton> Expected a string child. At key: ${i18nKey}, namespaces: ${
                t.ns
              }`
            );

            return <InfoButton {...props} />;
          },
          a: props => (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              rel="noopener noreferrer"
              // We can check for this
              ref={el => {
                if (el) {
                  if (el.hostname !== window.location.hostname) {
                    if (!el.target) {
                      el.target = '_blank';
                    }
                  }
                }
              }}
              {...props}
            />
          ),
        };

        return (
          <Markdown
            className={className}
            options={{
              forceInline: inline,
              overrides: {
                ...overrides,
                ...componentOverrides,
              },
            }}
          >
            {markdown || getMarkdownString(t, i18nKey)}
          </Markdown>
        );
      }}
    </ConsumeT>
  );
}

export default Text;
