import removeMD from 'remove-markdown';
import json2CSV from 'json2csv';
import i18n, { getSectionT } from '../i18n.js';
import { transliterate as fixUnicode } from 'transliteration';

export default ({ sectionSlugs, answers, ratings }) => {
  // Array item per answer of the form { section, question, current, target, note }
  const results = [];

  const getAnswerForHash = hash => answers[hash] || {};

  // Generate an item in the CSV per question/answer
  sectionSlugs.forEach(slug => {
    const sectionT = getSectionT(slug);
    const questions = sectionT('questions', { returnObjects: true });

    questions.forEach(question => {
      const answer = getAnswerForHash(question.hash);
      const current = ratings[answer.current]
        ? `${ratings[answer.current]} (${answer.current})`
        : 'n/a';
      const target = ratings[answer.target]
        ? `${ratings[answer.target]} (${answer.target})`
        : 'n/a';

      results.push({
        // Implementation copied to data-analysis-export.js
        [fixUnicode(i18n.t('results:download_csv_column_section'))]: fixUnicode(
          removeMD(sectionT('title'))
        ),
        [fixUnicode(
          i18n.t('results:download_csv_column_question')
        )]: fixUnicode(removeMD(question.text)),
        [fixUnicode(i18n.t('results:download_csv_column_current'))]: fixUnicode(
          current
        ),
        [fixUnicode(i18n.t('results:download_csv_column_target'))]: fixUnicode(
          target
        ),
        [fixUnicode(i18n.t('results:download_csv_column_note'))]: fixUnicode(
          answer.note || 'n/a'
        ),
      });
    });
  });

  return json2CSV.parse(results);
};
