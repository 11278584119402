import React from 'react';
import ReactDOM from 'react-dom';
import Advertisement from '../../Advertisement';
import { LayoutConsumer } from '../../../context/layout';

export default () => (
  <LayoutConsumer>
    {({ advertRef }) =>
      advertRef && ReactDOM.createPortal(<Advertisement />, advertRef)
    }
  </LayoutConsumer>
);
