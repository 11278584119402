import React, { Component, Fragment } from 'react';

class TextInput extends Component {
  render() {
    const {
      type,
      id,
      label,
      name,
      required,
      maxLength,
      tooltip,
      onChange,
      errorMessage,
      valid,
      value,
    } = this.props;

    return (
      <Fragment>
        <p>
          <label htmlFor={id} className={required ? 'required' : null}>
            {label}
          </label>
        </p>
        {tooltip && <div>{tooltip}</div>}
        <input
          value={value}
          type={type}
          id={id}
          name={name}
          required={required}
          maxLength={maxLength}
          onChange={onChange}
        />
        {!valid && errorMessage !== '' && (
          <div className="validation-message invalid">
            {label + errorMessage}
          </div>
        )}
      </Fragment>
    );
  }
}

export default TextInput;
