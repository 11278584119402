import { Component } from 'react';

export default class DelayRender extends Component {
  state = { ready: false };

  defaultDelayFn = time =>
    new Promise(resolve => {
      this.timeout = setTimeout(resolve, time);
    });

  async componentDidMount() {
    if (typeof this.props.delay !== 'number' && !this.props.delayFn) {
      return;
    }

    const delayFn = this.props.delayFn || this.defaultDelayFn;

    await delayFn(this.props.delay);

    this.setState({ ready: true });
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  isReady() {
    return this.props.ready || this.state.ready;
  }

  render() {
    if (this.isReady()) {
      return this.props.children;
    }

    return this.props.loadingEl || null;
  }
}
