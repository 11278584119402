import React, { Fragment, Component } from 'react';

import { withT } from '../context/t.js';

class InfoMessage extends Component {
  state = {
    closed: false,
  };

  onClose = () => {
    this.setState({ closed: true });
  };

  render() {
    return (
      <div
        role="alert"
        className="message--info"
        style={{ display: this.state.closed && 'none' }}
      >
        <button className="message__close" onClick={this.onClose}>
          <i className="fas fa-times" />
        </button>
        <div className="message__icon">
          <i className="fas fa-info-circle" />
        </div>
        <div className="message__text">
          {this.props.newUser ? (
            <Fragment>{this.props.t(`signup_new_user_description`)}</Fragment>
          ) : (
            <Fragment>
              {this.props.t(`signup_return_user_description`)}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withT('landing')(InfoMessage);
