import React, { Fragment, Component, createRef } from 'react';

import { withT } from '../../context/t';
import Sidemenu from './Sidemenu';
import Text from '../Text';

import { LayoutProvider } from '../../context/layout';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.mainRef = createRef();
    this.modalRef = createRef();
    this.advertRef = createRef();

    this.state = {
      mainRef: null,
      modalRef: null,
      advertRef: null,
    };
  }

  updateLayoutContext() {
    const { mainRef, modalRef, advertRef } = this.getRefs();

    if (
      mainRef !== this.mainRef.current ||
      modalRef !== this.modalRef.current ||
      advertRef !== this.advertRef.current
    ) {
      this.setState({
        mainRef: this.mainRef.current,
        modalRef: this.modalRef.current,
        advertRef: this.advertRef.current,
      });
    }
  }

  getRefs() {
    return {
      mainRef: this.state.mainRef,
      modalRef: this.state.modalRef,
      advertRef: this.state.advertRef,
    };
  }

  componentDidUpdate() {
    this.updateLayoutContext();
  }

  componentDidMount() {
    this.updateLayoutContext();
  }

  render() {
    return (
      <Fragment>
        <header className="payload-header">
          <div className="payload-title-wrapper">
            <div className="payload-title">
              <div className="main-title">
                <Text i18nKey="displayName" inline />
              </div>
              <div className="main-subtitle" />
            </div>
          </div>
          <div className="top-nav max-width">&nbsp;</div>
        </header>
        <div className="payload-3cols max-width">
          <div className="payload-col-2" ref={this.mainRef}>
            <LayoutProvider value={this.getRefs()}>
              {this.props.children}
            </LayoutProvider>
          </div>
          <div className="payload-col-1">
            <Sidemenu sidebarDisabled={this.props.sidebarDisabled} />
          </div>
          <div className="payload-col-3">
            <div className="infobox-container" ref={this.modalRef} />
            <div className="upsell-container" ref={this.advertRef} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withT('tool')(Layout);
