import deepmerge from 'deepmerge';

import toolConfig from './content/tool_config.json';

const defaultConfig = {
  advertisement: {
    enabled: false,
  },
  questions: {
    inputNotes: false,
  },
  results: {
    resultRating: 'percentage', // || 'outOf',
    csvDownload: {
      enabled: false,
    },
    chart: {
      download: {
        enabled: false,
      },
      enabled: false,
      options: {
        // Custom option used to set the background colour of the whole chart.
        // n.b. Includes the downloaded PNG
        backgroundColor: '#fff',
        animation: {},
        aspectRatio: 1,
        title: {
          display: true,
          fontColor: '#000000',
          fontFamily: "'MuseoSans', museo-sans, sans-serif",
          fontSize: 19,
          padding: 30,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 0,
          },
        },
        legend: {
          labels: {
            fontColor: '#000000',
            fontFamily: "'MuseoSans', museo-sans, sans-serif",
            fontSize: 14,
            padding: 20,
            usePointStyle: true,
          },
          position: 'bottom',
        },
        scale: {
          pointLabels: {
            fontColor: '#000000',
            fontFamily: "'MuseoSans', museo-sans, sans-serif",
            fontSize: 16,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      },
      max_styles: {
        borderColor: 'rgba(0,0,0, 0.035)',
        borderWidth: 0,
        backgroundColor: 'rgba(0,0,0, 0.035)',
        pointBackgroundColor: 'rgba(0,0,0, 0.035)',
        pointBorderColor: 'rgba(0,0,0, 0.035)',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#383b3d',
      },
      now_styles: {
        borderColor: '#51296A',
        borderWidth: 3,
        backgroundColor: 'rgba(81, 41, 106, 0.3)',
        pointBackgroundColor: '#51296A',
        pointBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#51296A',
      },
      future_styles: {
        borderColor: '#51296A',
        borderWidth: 3,
        backgroundColor: 'rgba(0,0,0,0)',
        pointBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderColor: '#51296A',
        borderDash: [15, 5],
      },
    },
  },
};

export default deepmerge(defaultConfig, toolConfig, {
  arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
});
