import React from 'react';
import { Route } from 'react-router-dom';

import { trackPageView } from '../lib/tracking';
import i18n from '../i18n';

const pageTitle = name =>
  `NCVO - ${i18n.t('tool:displayName')}${name ? ` - ${name}` : ''}`;

export default ({ pageName, render, ...props }) => {
  return (
    <Route
      {...props}
      render={(...args) => {
        document.title = pageTitle(pageName);
        trackPageView();

        return render(...args);
      }}
    />
  );
};
