import React from 'react';
import Spinner from 'react-spinner-material';
import DelayRender from './DelayRender';

export default ({ onClick, fileName, title, linkText, ready, imgSrc }) => (
  <div className="document hide-print">
    <DelayRender
      ready={ready}
      loadingEl={
        <Spinner size={100} spinnerColor="#eee" spinnerWidth={10} visible />
      }
    >
      <div className="docimage">
        <a onClick={onClick} href={fileName} title={title}>
          <img
            src={imgSrc}
            style={{ maxWidth: '100%' }}
            alt="Download preview"
          />
        </a>
      </div>
      <div className="doctext">{title}</div>
      <div className="doclink">
        <a onClick={onClick} href={fileName} title={`Download ${title}`}>
          {linkText}
        </a>
      </div>
    </DelayRender>
  </div>
);
