import React, { Component } from 'react';

import { withT } from '../context/t';

class NotesInput extends Component {
  handleChange = value => {
    this.props.onChange(value);
  };

  render() {
    return (
      <label>
        {this.props.t(`notes`)}:
        <textarea
          name="notes"
          cols="20"
          rows={this.props.value.split(/\n/).length}
          value={this.props.value}
          onChange={e => this.handleChange(e.currentTarget.value)}
        />
      </label>
    );
  }
}

export default withT('answerStructure')(NotesInput);
