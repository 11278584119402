import React, { Fragment } from 'react';

import { withT } from '../context/t.js';
import { getSectionT } from '../i18n.js';

import Link from './Link.jsx';

const LinkPrevNext = props => {
  return (
    <Fragment>
      <i className="fas fa-caret-left" />
      {props.previousSlug ? (
        <Link
          className="previous"
          to={'section/' + props.previousSlug}
          title={getSectionT(props.previousSlug)('title')}
        />
      ) : (
        <Link className="previous" to="" title={props.t('landing:title')} />
      )}
      {props.nextSlug ? (
        <Link
          className="next"
          to={'section/' + props.nextSlug}
          title={getSectionT(props.nextSlug)('title')}
        />
      ) : (
        <Link
          className="next"
          to={props.t(`results:slug`)}
          title={props.t('results:title')}
        />
      )}
      {
        // HACK: adds some spacing before the right-pointing arrow. This should not survive any refactor.
        // Ideas for the future:
        //  - stop using pseudoselectors to add the 'back to' and 'next:' content
        //  - instead, put those strings in translation strings and use them in this file
        //  - or, at the very least, display those strings in this file.
        ' '
      }
      <i className="fas fa-caret-right" />
    </Fragment>
  );
};

export default withT()(LinkPrevNext);
