import React, { Component } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import url from 'url';
import { createBrowserHistory } from 'history';

import Layout from './components/layout/Layout.jsx';
import Landing from './components/pages/Landing.jsx';
import Questions from './components/pages/Questions.jsx';
import Results from './components/pages/Results';
import LoadingSpinner from './components/LoadingSpinner.js';
import LinkPrevNext from './components/LinkPrevNext.jsx';

import TrackedRoute from './components/TrackedRoute';
import { withT } from './context/t';
import { SectionSlugsProvider } from './context/sectionSlugs';
import { findResult, getTool, setAPIPrefix, updateResult } from './api/api.js';
import { addRemoteTool, getSectionT } from './i18n.js';

import clientSideTool from './content/tool.json';

import singleJoiningSlash from './lib/singleJoiningSlash.js';

const { REACT_APP_APPLICATION_URL, NODE_ENV } = process.env;

const publicFacingPath = url.parse(REACT_APP_APPLICATION_URL || '/').pathname;

// Mount the API at the apiBasename or application url
// also done in setupProxy.js and server.js
setAPIPrefix(singleJoiningSlash(publicFacingPath, 'api'));
const history = createBrowserHistory({
  basename: publicFacingPath,
});

if (NODE_ENV === 'development') {
  if (publicFacingPath !== '/' && window.location.pathname === '/') {
    console.log(`redirecting to ${publicFacingPath} from env`);
    window.location.pathname = publicFacingPath;
  }
}

const PrivateRoute = ({ render, authed, ...rest }) => (
  <TrackedRoute
    {...rest}
    render={props => (authed ? render(props) : <Redirect to="/" />)}
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionSlugs: clientSideTool.sections.map(section => section.slug),
      resultId: props.initialResultId,
      toolVersion: this.props.t(`tool:version`),
      answers: {},
      // Checks whether user form has been sent - defaults to false
      sidebarDisabled: true,
      newUser: false,
      returningUser: false,
      loading: false,
    };
  }

  componentWillUnmount() {
    this.unlisten();
  }

  async componentDidMount() {
    const { resultId } = this.state;

    // If the hash changes we _don't_ want to scroll top
    // otherwise we do. TODO: Roll this into the state management
    let lastHash = '#' + this.props.hash;
    this.unlisten = history.listen(location => {
      if (location.hash === lastHash) {
        window.scrollTo(0, 0);
      } else {
        lastHash = location.hash;
      }
    });

    if (resultId) {
      this.setState({ loading: true });
      let response;
      try {
        response = await findResult(resultId);
      } catch (err) {
        console.error(err);
        this.setState({ loading: false });
        return;
      }

      if (response.status === 200) {
        // TODO: toolVersion differ from what's in this built resource
        // then alert the user they could be looking at an old
        // question set?
        let result;
        try {
          result = await response.json();
        } catch (err) {
          console.error(err);
          this.setState({ loading: false });
          return;
        }

        // get the tool
        let toolResponse;
        try {
          toolResponse = await getTool(result.toolVersion);
        } catch (err) {
          console.error(err);
          this.setState({ loading: false });
          return;
        }

        if (toolResponse.status === 200) {
          // populate the state/translations with the sections and questions
          let tool;
          try {
            tool = await toolResponse.json();
          } catch (err) {
            console.error(err);
            this.setState({ loading: false });
            return;
          }

          addRemoteTool(tool);

          // This has a side-effect to force a re-render, to force t function to use
          // correct namespaces - this might not work all the time?
          this.setState(state => ({
            sectionSlugs: tool.sections.map(section => section.slug),
            returningUser: true,
            sidebarDisabled: false,
            toolVersion: tool.version,
            newUser: false,
            answers: result.answers,
          }));
        }
      }
      this.setState({ loading: false });
    }
  }

  handleUpdate = (field, questionHash, newVal) => {
    this.setState(state => {
      const oldAnswer = state.answers[questionHash] || {};

      return {
        answers: {
          ...state.answers,
          [questionHash]: {
            ...oldAnswer,
            [field]: newVal,
          },
        },
      };
    });
  };

  handleSignedUp = resultId => {
    this.setState({
      newUser: true,
      resultId,
      sidebarDisabled: false,
      loading: false,
    });
    window.location.hash = resultId;
  };

  handleFinishedNetwork = () => {
    this.setState({ loading: false });
  };

  handleSectionSave = async section => {
    this.setState({ loading: true });
    try {
      await Promise.all([
        updateResult({
          resultId: this.state.resultId,
          answers: this.state.answers,
        }),
        new Promise(r => setTimeout(r, 1500)),
      ]);
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
  };

  /**
   * Return previous and next section slugs
   *
   * returns previous and next as a string or undefined
   */
  getPrevNextSectionSlug(sectionSlug) {
    // Get current section index
    const sectionSlugs = this.state.sectionSlugs;
    const sectionIndex = sectionSlugs.indexOf(sectionSlug);
    const lastSectionIndex = sectionSlugs.length - 1;
    let previous, next;
    // Determine whether previous/next aren't valid (on first & last section)
    if (sectionIndex === -1) {
      return null;
    } else if (sectionIndex === 0) {
      next = sectionSlugs[sectionIndex + 1];
    } else if (sectionIndex === lastSectionIndex) {
      previous = sectionSlugs[sectionIndex - 1];
    } else {
      previous = sectionSlugs[sectionIndex - 1];
      next = sectionSlugs[sectionIndex + 1];
    }

    return { previous, next };
  }

  render() {
    const { sidebarDisabled, newUser, returningUser } = this.state;

    return (
      <Router history={history}>
        <SectionSlugsProvider value={this.state.sectionSlugs}>
          <Layout sidebarDisabled={sidebarDisabled}>
            <LoadingSpinner visible={this.state.loading} />
            <Switch>
              <TrackedRoute
                // Default route
                path="/"
                pageName={this.props.t('landing:title')}
                exact
                render={props => (
                  <Landing
                    onStartSignupNetwork={() =>
                      this.setState({ loading: true })
                    }
                    onSignedUp={this.handleSignedUp}
                    newUser={newUser}
                    returningUser={returningUser}
                    onStopLoading={this.handleFinishedNetwork}
                    contentVersion={this.state.toolVersion}
                    {...props}
                  />
                )}
              />
              {this.state.sectionSlugs.map(currentSection => {
                const { previous, next } = this.getPrevNextSectionSlug(
                  currentSection
                );
                const t = getSectionT(currentSection);

                return (
                  <PrivateRoute
                    // We need to force the component to unmount when the section changes
                    // so we know when to save the answers
                    key={this.state.toolVersion + currentSection}
                    path={`/section/${currentSection}`}
                    authed
                    pageName={t('title')}
                    render={() => (
                      <Questions
                        onUpdate={this.handleUpdate}
                        onSave={this.handleSectionSave}
                        sectionSlug={currentSection}
                        answers={this.state.answers}
                        prevNextLink={
                          <LinkPrevNext
                            previousSlug={previous}
                            nextSlug={next}
                          />
                        }
                        onUnmount={() => this.handleSectionSave(currentSection)}
                      />
                    )}
                  />
                );
              })}
              <PrivateRoute
                pageName={this.props.t('results:title')}
                path={'/' + this.props.t(`results:slug`)}
                authed
                render={props => <Results answers={this.state.answers} />}
              />
            </Switch>
          </Layout>
        </SectionSlugsProvider>
      </Router>
    );
  }
}

export default withT()(App);
