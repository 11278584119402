import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

const Link = props => {
  const { className = '', to, title, disabled } = props;

  /* eslint-disable jsx-a11y/anchor-is-valid */
  // if disabled return an empty anchor, should be a span but we don't control the css
  if (disabled) {
    return (
      <a
        className={`disabled ${className}`}
        disabled="disabled"
        tabIndex="0"
      >
        {title}
      </a>
    );
  }
  /* eslint-enable jsx-a11y/anchor-is-valid */

  return (
    <RouterLink
      className={className}
      to={{
        pathname: '/' + to,
        hash: window.location.hash,
      }}
    >
      {title}
    </RouterLink>
  );
};

export default Link;
