import React, { PureComponent } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { withT } from '../context/t';

const HoverStateContainer = styled.div``;

const NonHoverStateContainer = styled.div``;

const VisuallyHidden = styled.div`
  position: absolute;
  opacity: 0;
`;

const Stars = styled.label`
  & + & {
    padding-left: 10px;
  }
`;

const RadioIcon = styled.div`
  font-size: 2rem;
  font-family: 'Font Awesome 5 Free';

  ::before {
    content: '\f111';
  }
`;

const FilledRadioIcon = styled.div`
  font-size: 2rem;
  font-family: 'Font Awesome 5 Free';

  ::before {
    content: '\f192';
  }
`;

const HiddenLegend = styled.legend`
  opacity: 0;
  position: absolute;
`;

const InputContainer = styled.div`
  display: inline-flex;
  padding: 2px;

  &:hover ${NonHoverStateContainer},
  ${HoverStateContainer} {
    display: none;
  }

  ${NonHoverStateContainer},
  &:hover ${HoverStateContainer} {
    display: block;
  }
`;

let nInputs = 0;

export class RatingInput extends PureComponent {
  static defaultProps = {
    onSelection: Function.prototype,
  };

  name = `${nInputs++}_rating_input`;

  state = {
    hoverValue: null,
    focused: false,
  };

  handleChange = evt =>
    this.props.onSelection(parseInt(evt.currentTarget.value, 10));

  renderRatingTag(hover) {
    let ratingText = this.props.t(`default`);
    let value;

    if (hover) {
      value = this.state.hoverValue;
    } else {
      value = this.props.value;
    }

    if (typeof value === 'number') {
      ratingText = this.props.t(`ratings.${value}`);
    }

    return (
      <div className="desc">
        <span>{ratingText}</span>
      </div>
    );
  }

  renderVisualRadio(value, radioValue) {
    let visual = <RadioIcon className="radio-icon" />;

    if (typeof value === 'number') {
      if (value >= radioValue) {
        visual = <FilledRadioIcon />;
      }
    }

    return visual;
  }

  render() {
    const { value, label, className, type } = this.props;
    const { hoverValue } = this.state;

    const ratings = this.props.t(`ratings`, { returnObjects: true });
    const targetLabel = this.props.t(label);

    return (
      <div className={className}>
        <fieldset
          className={classNames(type, 'rating-input', {
            'rating-input--active': this.state.focused,
          })}
          onFocus={() => this.setState({ focused: true })}
          // react don't support on focus out, we need to use this to determine
          onBlur={e => {
            // focus is leaving the container so do something interesting here
            if (!e.currentTarget.contains(e.relatedTarget)) {
              this.setState({ focused: false });
            }
          }}
        >
          <HiddenLegend>{targetLabel + ':'}</HiddenLegend>
          <InputContainer className={type}>
            <span className="descriptor">{targetLabel + ':'}</span>
            {ratings.map((rating, radioValue) => {
              const checked = value === radioValue;

              return (
                <Stars
                  key={rating}
                  onMouseEnter={() => this.setState({ hoverValue: radioValue })}
                >
                  <VisuallyHidden>
                    <input
                      onChange={this.handleChange}
                      type="radio"
                      value={radioValue}
                      name={this.name}
                      checked={checked}
                    />
                    <span>{rating}</span>
                  </VisuallyHidden>
                  <NonHoverStateContainer>
                    {this.renderVisualRadio(value, radioValue)}
                  </NonHoverStateContainer>
                  <HoverStateContainer>
                    {this.renderVisualRadio(hoverValue, radioValue)}
                  </HoverStateContainer>
                </Stars>
              );
            })}
            <NonHoverStateContainer>
              {this.renderRatingTag()}
            </NonHoverStateContainer>
            <HoverStateContainer>
              {this.renderRatingTag(true)}
            </HoverStateContainer>
          </InputContainer>
        </fieldset>
      </div>
    );
  }
}

export default withT('answerStructure')(RatingInput);
