import 'core-js';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

// Third party assets
import '@fortawesome/fontawesome-free/css/all.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

let isWin7IE11 = false;
// HORRIBLE HACK
// windows7 ie11 prints an error from deep within windows when attempting to print.
// It seems this CSS will fix the print problem. Never detect browsers/OS versions like this.
// If windows 7
if (navigator.userAgent.indexOf('Windows NT 6.1')) {
  // from http://browserhacks.com/#hack-acea075d0ac6954f275a70023906050c
  isWin7IE11 =
    '-ms-scroll-limit' in document.documentElement.style &&
    '-ms-ime-align' in document.documentElement.style;
}

// We share our hashfragment with the Front Controller, extract only the bits we want.
const UUIDv4_REGEX = /([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i;
const getInitialID = () => {
  const hash = window.location.hash;

  const match = hash.match(UUIDv4_REGEX);

  if (match) {
    return match[1];
  }

  return null;
};

ReactDOM.render(
  <Fragment>
    {isWin7IE11 && (
      <style>{`
        @media print {
          /* Horrible ie11 windows 7 hack */
          * {
            font-family: "MuseoSans", museo-sans, sans-serif !important;
          }
        }
      `}</style>
    )}
    <App initialResultId={getInitialID()} />
  </Fragment>,
  document.getElementById('payload-wrapper')
);

serviceWorker.unregister();
