import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';
import { withT } from '../context/t';
import Text from './Text';

const Title = styled.h4`
  text-transform: capitalize;
`;

const getPixelOffset = ({ y_pos }) => (y_pos ? y_pos + 'px' : '0px');

const InfoContainer = styled.div`
  margin-top: ${getPixelOffset};
  margin-top: calc(
    ${getPixelOffset} - 1rem
  ); /* 1rem is from the padding on the box from content-component */
`;

const CloseButton = styled.button`
  min-width: 0;
  margin: 0;
  padding: 0;
`;

const Icon = styled.i`
  margin: 0;
`;

class InfoBox extends Component {
  constructor(props) {
    super(props);

    this.parentDiv = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.parentDiv);
  }

  componentWillUnmount() {
    document.body.removeChild(this.parentDiv);
  }

  renderBox() {
    const { isOpen, y_pos = 0, subject } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Fragment>
        <InfoContainer className="info-box" y_pos={y_pos}>
          <CloseButton
            className="close-button close-button-positioned"
            onClick={this.props.onClose}
          >
            <Icon className="fas fa-times close-icon" />
            <span className="sr-only">Close</span>
          </CloseButton>
          <Title>{subject}</Title>
          <p>
            <Text i18nKey={`${subject}.explanation`} />
          </p>
        </InfoContainer>
      </Fragment>
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.renderBox(),
      this.props.container || this.parentDiv
    );
  }
}

export default withT('glossary')(InfoBox);
