import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import omit from 'lodash.omit';

import tool from './content/tool.json';
import landing from './content/landing.json';
import results from './content/result.json';
import glossary from './content/glossary.json';

const resources = {
  en: {
    tool: omit(tool, 'sections', 'answerStructure'),
    answerStructure: tool.answerStructure,
    landing: landing,
    results: results,
    glossary: glossary,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    // saveMissing: true,
  });

// [`section_${section.slug}`, `section_${section.slug}_remote`] // from Questions.jsx
// Load local resources from sections matching namespace pattern above
tool.sections.forEach(section => {
  i18n.addResourceBundle('en', `section_${section.slug}`, section);
});

export const addRemoteTool = tool => {
  // TODO: look at deep merge vs fallback namespaces
  const newTool = omit(tool, 'sections', 'answerStructure');
  i18n.addResourceBundle('en', 'tool', newTool, true, true);
  i18n.addResourceBundle(
    'en',
    'answerStructure',
    tool.answerStructure,
    true,
    true
  );

  tool.sections.forEach(section => {
    const namespace = `section_${section.slug}_remote`;

    i18n.addResourceBundle('en', namespace, section);
  });
};

export const getSectionT = sectionSlug => {
  return i18n.getFixedT(
    null,
    // namespaces are in reverse order, fallbacks from left to right
    [`section_${sectionSlug}_remote`, `section_${sectionSlug}`]
  );
};

export default i18n;
