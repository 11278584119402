import React, { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';

const defaultT = key => key;

const { Consumer: ConsumeT, Provider } = createContext(defaultT);

export const withT = namespace => {
  return Component => {
    return ({ ...props }) => {
      const { t, i18n } = useTranslation(namespace);

      return (
        <Provider value={t}>
          <Component {...props} t={t} i18n={i18n} />
        </Provider>
      );
    };
  };
};

export { Provider as TProvider };

export default ConsumeT;
