import 'isomorphic-fetch';

let apiPrefix = '';

export const setAPIPrefix = newPrefix => {
  apiPrefix = newPrefix.replace(/\/$/, '');
};

/**
 * Pass the user object with the keys:
 *  email
 *  firstName
 *  lastName
 *  organisationName
 *  role
 *  emailOptin
 *  signedUpToolVersion
 * @param {Object} userObject
 */
export const createUser = userObject =>
  fetch(`${apiPrefix}/user/add`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userObject),
  });

export const createResult = ({ userId, toolVersion }) =>
  fetch(`${apiPrefix}/result/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      toolVersion,
    }),
  });

export const findResult = resultId =>
  fetch(`${apiPrefix}/result/find`, {
    headers: {
      // URLs are sent in the clear, headers and post body will be ssl'd
      // TODO: Use auth instead
      'Result-Id': resultId,
    },
  });

export const getTool = toolVersion =>
  fetch(`${apiPrefix}/tool/get?id=${toolVersion}`);

export const updateResult = ({ resultId, answers }) =>
  fetch(`${apiPrefix}/result/update`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      resultId,
      answers,
    }),
  });
