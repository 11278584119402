import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinner-material';

const SpinnerContainer = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.75);
  z-index: 99;
  color: #eee;
`;

export default ({ visible }) => (
  <SpinnerContainer visible={visible}>
    <p>Saving...</p>
    <Spinner size={100} spinnerColor="#eee" spinnerWidth={10} visible />
  </SpinnerContainer>
);
