import React, { Fragment } from 'react';

import { withT } from '../../context/t';
import { SectionSlugsConsumer } from '../../context/sectionSlugs';

import Link from '../Link';
import RatingInput from '../RatingInput';
import SectionTitleDesc from '../SectionTitleDesc';
import Text from '../Text';
import TextInput from '../TextInput';
import InfoMessage from '../InfoMessage';

import { createUser, createResult } from '../../api/api';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: { value: '', isValid: false, error: '' },
      firstName: { value: '', isValid: false, error: '' },
      lastName: { value: '', isValid: false, error: '' },
      organisationName: { value: '', isValid: false, error: '' },
      role: { value: '', isValid: false, error: '' },
      emailOptin: { value: false },
      current: false,
      target: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const inputName = event.target.name;
    const target = event.target;

    this.setState(state => ({
      [inputName]: {
        ...state[inputName],
        value: target.type === 'checkbox' ? target.checked : target.value,
      },
    }));
  }

  handleRating = (field, newVal) => {
    this.setState({
      [field]: newVal,
    });
  };

  validateFields() {
    const { email, firstName, lastName, organisationName, role } = this.state;

    // Regex to match default HTML5 email validation https://www.w3.org/TR/2012/WD-html-markup-20120320/input.email.html
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const nameInputs = [firstName, lastName];

    // email
    if (email.value === '') {
      email.error = this.props.t('signup_required');
    } else if (email.value.length > 50) {
      email.error = this.props.t('signup_too_long');
    } else if (!emailRegex.test(email.value)) {
      email.error = this.props.t('signup_email_format');
    } else {
      email.isValid = true;
      email.error = '';
    }

    // names
    nameInputs.forEach(name => {
      if (name.value === '') {
        name.error = this.props.t('signup_required');
      } else if (name.value.length > 50) {
        name.error = this.props.t('signup_too_long');
      } else {
        name.isValid = true;
        name.error = '';
      }
    });

    // optional
    if (organisationName.value.length > 100) {
      organisationName.error = this.props.t('signup_too_long');
    } else {
      organisationName.isValid = true;
      organisationName.error = '';
    }

    if (role.value.length > 50) {
      role.error = this.props.t('signup_too_long');
    } else {
      role.isValid = true;
      role.error = '';
    }

    this.setState({ email, firstName, lastName, organisationName, role });

    return (
      email.isValid &&
      firstName.isValid &&
      lastName.isValid &&
      organisationName.isValid &&
      role.isValid
    );
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.validateFields()) {
      return;
    }

    this.props.onStartSignupNetwork();

    // POST Landing state from form into users DB table.
    let response;

    try {
      response = await createUser({
        email: this.state.email.value,
        firstName: this.state.firstName.value,
        lastName: this.state.lastName.value,
        organisationName: this.state.organisationName.value,
        role: this.state.role.value,
        emailOptin: this.state.emailOptin.value,
        signedUpToolVersion: this.props.contentVersion,
      });
    } catch (e) {
      console.error(e);
      return this.props.onStopLoading();
    }

    if (!response.status === 200) {
      // TODO: Handle this error
      console.error(response);
      console.error(await response.text());
      return this.props.onStopLoading();
    }

    const { userId } = await response.json();

    // POST App state & userId into answers DB table.
    let answersResponse;

    try {
      answersResponse = await createResult({
        userId: userId,
        toolVersion: this.props.contentVersion,
      });
    } catch (e) {
      console.error(e);
      return this.props.onStopLoading();
    }

    if (!answersResponse.status === 200) {
      // TODO: handle error
      console.error(answersResponse);
      console.error(await answersResponse.text());
      return this.props.onStopLoading();
    }

    const { resultId } = await answersResponse.json();

    // Pass resultId from response back up to be accessed in App.
    this.props.onSignedUp(resultId);
    window.scrollTo(0, 0);
  }

  renderForm() {
    const {
      email,
      firstName,
      lastName,
      role,
      organisationName,
      emailOptin,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <h3>{this.props.t(`signup_description`)}</h3>
        <TextInput
          type="email"
          id="email"
          name="email"
          label={this.props.t(`signup_input_email`)}
          required={true}
          maxLength={50}
          value={email.value}
          onChange={this.handleInputChange}
          valid={email.isValid}
          errorMessage={email.error}
        />
        <TextInput
          type="text"
          id="firstName"
          name="firstName"
          label={this.props.t(`signup_input_firstname`)}
          required={true}
          maxLength={50}
          value={firstName.value}
          onChange={this.handleInputChange}
          valid={firstName.isValid}
          errorMessage={firstName.error}
        />
        <TextInput
          type="text"
          id="lastName"
          name="lastName"
          label={this.props.t(`signup_input_lastname`)}
          required={true}
          maxLength={50}
          value={lastName.value}
          onChange={this.handleInputChange}
          valid={lastName.isValid}
          errorMessage={lastName.error}
        />
        <TextInput
          type="text"
          id="organisationName"
          name="organisationName"
          label={this.props.t(`signup_input_organisation_name`)}
          required={false}
          maxLength={100}
          value={organisationName.value}
          onChange={this.handleInputChange}
          valid={organisationName.isValid}
          errorMessage={organisationName.error}
        />
        <TextInput
          type="text"
          id="role"
          name="role"
          label={this.props.t(`signup_input_role`)}
          required={false}
          maxLength={50}
          value={role.value}
          onChange={this.handleInputChange}
          valid={role.isValid}
          errorMessage={role.error}
        />
        <div>
          <label>
            <input
              type="checkbox"
              name="emailOptin"
              checked={emailOptin.value}
              onChange={this.handleInputChange}
            />
            {this.props.t(`signup_email_permission`)}
          </label>
        </div>
        <br />
        <p>
          <Text i18nKey={'signup_privacy'} inline />
        </p>
        <button type="submit">Get Started</button>
      </form>
    );
  }

  renderNextButton() {
    return (
      <Fragment>
        <section>
          <div className="pagenav max-width">
            <SectionSlugsConsumer>
              {sectionSlugs => (
                <Link
                  className="next"
                  to={'section/' + sectionSlugs[0]}
                  title={this.props.t(`nextButton`)}
                  iconRight
                />
              )}
            </SectionSlugsConsumer>
          </div>
        </section>
      </Fragment>
    );
  }

  render() {
    const shouldShowDescription =
      !this.props.newUser && !this.props.returningUser;

    let sections = this.props.t(`steps`, { returnObjects: true });
    if (this.props.returningUser || this.props.newUser) {
      sections = sections.slice(1);
    }

    return (
      <Fragment>
        <SectionTitleDesc
          title={this.props.t(`title`)}
          description={
            shouldShowDescription && <Text inline i18nKey="description" />
          }
        />
        {(this.props.newUser || this.props.returningUser) && (
          <Fragment>
            {this.props.newUser ? <InfoMessage newUser /> : <InfoMessage />}
          </Fragment>
        )}

        <ul>
          {sections.map((step, i) => (
            <li key={i}>
              <h3>
                Step {i + 1}: <Text inline markdown={step.name} />
              </h3>
              <Text inline markdown={step.text} />
              {i === 1 ? (
                <Fragment>
                  <RatingInput
                    value={this.state.current}
                    label="current"
                    onSelection={newRating =>
                      this.handleRating('current', newRating)
                    }
                    type="now"
                  />
                  <RatingInput
                    value={this.state.target}
                    label="target"
                    onSelection={newRating =>
                      this.handleRating('target', newRating)
                    }
                    type="future"
                  />
                </Fragment>
              ) : null}
            </li>
          ))}
        </ul>
        <p>
          <Text i18nKey="post_steps_blurb" />
        </p>
        {!this.props.newUser && !this.props.returningUser && this.renderForm()}
        {this.props.newUser || this.props.returningUser
          ? this.renderNextButton()
          : null}
      </Fragment>
    );
  }
}

export default withT('landing')(Landing);
