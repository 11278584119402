import React, { Fragment, Component } from 'react';

import styled from 'styled-components';
import Chart from 'chart.js';
import { Radar } from 'react-chartjs-2';
import Spinner from 'react-spinner-material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import csvSVG from '@fortawesome/fontawesome-free/svgs/solid/file-csv.svg';

import { getSectionT } from '../../../i18n.js';
import Text from '../../Text.jsx';
import { withT } from '../../../context/t';
import { SectionSlugsConsumer } from '../../../context/sectionSlugs';
import SectionTitleDesc from '../../SectionTitleDesc';
import DelayRender from '../../DelayRender.js';
import config from '../../../config.js';
import logo from '../styles/ncvo.png';
import DocumentDownload from '../../DocumentDownload.js';
import createCSVResults from '../../../lib/createCSVResults.js';
import AdvertisementSidebar from './AdvertisementSidebar.js';
// this modifies the canvas prototype
import './canvas-toBlob';

const ChartContainer = styled.div`
  margin-bottom: 4rem;
  position: relative;

  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

Chart.pluginService.register({
  beforeDraw: function(chart) {
    const bgColor = chart.config.options.backgroundColor;

    if (bgColor) {
      const ctx = chart.chart.ctx;

      ctx.save();
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  },
});

const b64ToBytes = b64Str => {
  // Remove the data uri start and remove padding of "=" signs
  const b64length =
    b64Str.replace(/=/g, '').length - 'data:image/png;base64,'.length;
  const paddedBits = (b64length / 4) * 3;

  return paddedBits;
};

const byteCount = s => encodeURI(s).split(/%..|./).length - 1;

const download = (data, fileName, mime) => {
  const file = new Blob([data], { type: mime });
  saveAs(file, fileName);
};

class Results extends Component {
  state = {
    chartRendered: false,
    chartBlob: '',
    chartB64: '',
    chartImageSize: 0,
  };

  getMax(sectionSlug) {
    const questions = getSectionT(sectionSlug)('questions', {
      returnObjects: true,
    });
    const ratingsOptions =
      this.props.t('answerStructure:ratings', { returnObjects: true }).length -
      1;

    return questions.length * ratingsOptions;
  }

  getAnswerForHash(hash) {
    return this.props.answers[hash] || {};
  }

  sumAnswers(sectionSlug, field) {
    // Get current results array for section passed.
    const questions = getSectionT(sectionSlug)('questions', {
      returnObjects: true,
    });

    // Add the results within array.
    return questions.reduce((acc, question) => {
      const answerObj = this.getAnswerForHash(question.hash);

      return acc + (answerObj[field] || 0);
    }, 0);
  }

  calculateTotal(sectionSlugs, field, total) {
    const totalCurrentResults = sectionSlugs.reduce(
      (accumulator, sectionSlug) =>
        accumulator + this.sumAnswers(sectionSlug, field),
      0
    );

    const totalMaximumPossible = sectionSlugs.reduce(
      (accumulator, sectionSlug) => accumulator + this.getMax(sectionSlug),
      0
    );

    const roundedPercentage = Math.round(
      (totalCurrentResults / totalMaximumPossible) * 100
    );

    if (total === 'outOf') {
      return totalCurrentResults + '/' + totalMaximumPossible + ' ';
    } else {
      return roundedPercentage + '%';
    }
  }

  renderSection(sectionSlug) {
    const t = getSectionT(sectionSlug);

    return (
      <div key={sectionSlug} className="clearfix cat-details__section">
        <div className="cat-name" key={t('title')}>
          <i className={'fas ' + t('icon')} />
          {t('title')}
        </div>
        <div className="cat-figs">
          <div className="cat-got">
            We currently score {this.sumAnswers(sectionSlug, 'current')}/
            {this.getMax(sectionSlug)}
          </div>
          <div className="cat-wanted">
            We plan to score {this.sumAnswers(sectionSlug, 'target')}/
            {this.getMax(sectionSlug)}
          </div>
        </div>
      </div>
    );
  }

  // To render questions within sections when page is printed. Similar logic in Questions.jsx.
  renderSectionQuestions(sectionSlug) {
    const t = getSectionT(sectionSlug);

    return (
      <Fragment key={sectionSlug}>
        <div
          className="section-questions"
          style={{ height: 'auto', marginTop: 0 }}
        >
          {this.renderSection(sectionSlug)}
          {t(`questions`, { returnObjects: true }).length ? (
            <ul style={{ padding: '0 1rem', boxSizing: 'border-box' }}>
              {t(`questions`, { returnObjects: true }).map((question, id) => (
                <li
                  key={id}
                  style={{
                    fontWeight: 'normal',
                    fontSize: '1rem',
                    marginBottom: '1rem',
                  }}
                >
                  {this.renderQuestion(question)}
                </li>
              ))}
            </ul>
          ) : (
            <p>There are not yet any questions for this section</p>
          )}
        </div>
      </Fragment>
    );
  }

  defaultRating(rating) {
    if (typeof rating === 'number') {
      return rating;
    }
    return null;
  }

  // To render each question when page is printed. Similar to method in Questions.jsx.
  renderQuestion(question) {
    const answer = this.getAnswerForHash(question.hash);
    const ratingsOptions = this.props.t('answerStructure:ratings', {
      returnObjects: true,
    });
    const currentLabel = this.props.t('answerStructure:current', {
      returnObjects: true,
    });
    const targetLabel = this.props.t('answerStructure:target', {
      returnObjects: true,
    });

    return (
      <section key={question.hash}>
        <h4
          style={{
            fontSize: '1.2rem',
            fontWeight: 'bold',
            boxSizing: 'border-box',
          }}
        >
          <Text
            markdown={question.text}
            componentOverrides={{
              // Remove the Icon and button-nature of the InfoButton components
              // TODO: This could overlap with the other place we strip markdown. Could there be a solution for both?
              InfoButton: ({ children }) => children,
            }}
          />
        </h4>
        <p>
          <span style={{ fontSize: '1.1rem' }}>{currentLabel}:</span>{' '}
          {ratingsOptions[answer.current]}
        </p>
        <p>
          <span style={{ fontSize: '1.1rem' }}>{targetLabel}:</span>{' '}
          {ratingsOptions[answer.target]}
        </p>
        <h5 style={{ fontSize: '1rem', margin: '0.25rem 0' }}>Notes:</h5>
        <p>{answer.note}</p>
      </section>
    );
  }

  getChartData(sectionSlugs) {
    const labels = sectionSlugs.map(sectionSlug => {
      // The label is split into an array so that it displays on multiple lines in Chart.js
      const title = getSectionT(sectionSlug)('title');

      return title.split('and').map((a, i, arr) => {
        if (i < arr.length - 1) {
          return a + 'and';
        }

        return a;
      });
    });

    const max = sectionSlugs.map(sectionSlug => {
      return this.getMax(sectionSlug);
    });

    const results = sectionSlugs.map(sectionSlug =>
      this.sumAnswers(sectionSlug, 'current')
    );

    const target = sectionSlugs.map(sectionSlug =>
      this.sumAnswers(sectionSlug, 'target')
    );

    const data = {
      labels: labels,
      datasets: [
        {
          ...config.results.chart.max_styles,
          label: this.props.t(`chart_max_label`),
          data: max,
        },
        {
          ...config.results.chart.now_styles,
          label: this.props.t('chart_now_label'),
          data: results,
        },
        {
          ...config.results.chart.future_styles,
          label: this.props.t('chart_future_label'),
          data: target,
        },
      ],
    };

    return data;
  }

  renderChart() {
    return (
      <SectionSlugsConsumer>
        {sectionSlugs => (
          <Radar
            height={null}
            width={null}
            data={this.getChartData(sectionSlugs)}
            options={{
              ...config.results.chart.options,
              title: {
                ...config.results.chart.options.title,
                text: this.props.t('chart_title'),
              },
              animation: {
                ...config.results.chart.options.animation,
                onComplete: chartElement => {
                  chartElement.chart.canvas.style.backgroundColor = '#fff';
                  const { canvas } = chartElement.chart;
                  const chartB64 = chartElement.chart.toBase64Image();

                  canvas.toBlob(chartBlob => {
                    this.setState({
                      chartRendered: true,
                      chartBlob,
                      chartB64,
                      // TODO: use a proper byte size calc now that we have the blob
                      chartImageSize: b64ToBytes(chartB64),
                    });
                  });
                },
              },
            }}
          />
        )}
      </SectionSlugsConsumer>
    );
  }

  renderDownloadChart() {
    const format = 'png';
    const sizeKB = (this.state.chartImageSize / 1000).toFixed(2);
    const date = DateTime.local().toISODate();

    const title = this.props.t('download_chart_title');
    const linkText = this.props.t('download_chart_link_text', {
      format: format.toUpperCase(),
      date,
      sizeKB,
    });
    const fileName = this.props.t('download_chart_filename', {
      format,
      date,
      sizeKB,
    });

    const handleClick = evt => {
      evt.preventDefault();
      download(this.state.chartBlob, fileName, 'data:image/png;base64');
    };

    return (
      <DocumentDownload
        onClick={handleClick}
        fileName={fileName}
        title={title}
        linkText={linkText}
        ready={this.state.chartRendered}
        imgSrc={this.state.chartB64}
      />
    );
  }

  renderDownloadCSV(sectionSlugs) {
    const format = 'csv';
    const date = DateTime.local().toISODate();

    const ratings = this.props.t('answerStructure:ratings', {
      returnObjects: true,
    });
    const csv = createCSVResults({
      sectionSlugs,
      answers: this.props.answers,
      ratings,
    });

    const sizeKB = (byteCount(csv) / 1000).toFixed(2);
    const title = this.props.t('download_csv_title');
    const linkText = this.props.t('download_csv_link_text', {
      format: format.toUpperCase(),
      date,
      sizeKB,
    });
    const fileName = this.props.t('download_csv_filename', {
      format,
      date,
      sizeKB,
    });

    const handleClick = evt => {
      evt.preventDefault();

      // convert the array to a string of csv
      download(csv, fileName, 'text/csv');
    };

    return (
      <DocumentDownload
        onClick={handleClick}
        fileName={fileName}
        title={title}
        ready
        linkText={linkText}
        imgSrc={csvSVG}
      />
    );
  }

  render() {
    return (
      <Fragment>
        <img className="print-logo" src={logo} alt="NCVO logo" />
        <div className="print-summary print-centered">
          <SectionTitleDesc
            title={<Text i18nKey="title" />}
            description={<Text i18nKey="description" />}
            icon={'fa-poll'}
          />
          <h2>Overall Score</h2>
          <SectionSlugsConsumer>
            {sectionSlugs => (
              <Fragment>
                <div className="overall-score">
                  We currently score&nbsp;
                  <span className="achieved-fig">
                    {this.calculateTotal(
                      sectionSlugs,
                      'current',
                      config.results.resultRating
                    )}
                  </span>
                  We plan to score&nbsp;
                  <span className="aim-fig">
                    {this.calculateTotal(
                      sectionSlugs,
                      'target',
                      config.results.resultRating
                    )}
                  </span>
                </div>
                <div className="cat-details">
                  {sectionSlugs.map(section => this.renderSection(section))}
                </div>
              </Fragment>
            )}
          </SectionSlugsConsumer>
        </div>
        <SectionSlugsConsumer>
          {sectionSlugs => (
            <Fragment>
              <div className="cat-print-details clearfix">
                {sectionSlugs.map(section =>
                  this.renderSectionQuestions(section)
                )}
              </div>
            </Fragment>
          )}
        </SectionSlugsConsumer>
        {config.results.chart.enabled && (
          <div>
            <ChartContainer>
              {/* Delay the render of the Graph so the dom can catch up and report its size properly. */}
              <DelayRender
                delay={500}
                loadingEl={
                  <Spinner
                    size={100}
                    spinnerColor="#eee"
                    spinnerWidth={10}
                    visible
                  />
                }
              >
                {this.renderChart()}
              </DelayRender>
            </ChartContainer>
            {config.results.chart.download.enabled &&
              this.renderDownloadChart()}
          </div>
        )}
        {config.results.csvDownload.enabled && (
          <SectionSlugsConsumer>
            {sectionSlugs => this.renderDownloadCSV(sectionSlugs)}
          </SectionSlugsConsumer>
        )}
        <Text i18nKey="extra_information" />
        <div className="pagenav max-width">
          <button type="button" onClick={window.print} className="hide-print">
            Print
          </button>
        </div>
        {config.advertisement.enabled && <AdvertisementSidebar />}
      </Fragment>
    );
  }
}

export default withT('results')(Results);
