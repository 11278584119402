import React from 'react';
import { withRouter } from 'react-router';

import { getSectionT } from '../../i18n.js';
import { withT } from '../../context/t';
import { SectionSlugsConsumer } from '../../context/sectionSlugs';
import Link from '../Link.jsx';

const Sidemenu = props => {
  const { formFilled, sidebarDisabled, location } = props;
  const path = location.pathname;
  var activePage = path
    .split('/')
    .slice(-1)
    .pop();

  return (
    <nav className="diagnostics-questions hide-print">
      <ul>
        <li className={'nav-button' + (activePage === '' ? ' active' : '')}>
          <Link to="" title={props.t('landing:title')} />
        </li>
        <SectionSlugsConsumer>
          {sectionSlugs =>
            sectionSlugs.map(sectionSlug => (
              <li
                className={
                  'nav-button' + (activePage === sectionSlug ? ' active' : '')
                }
                key={sectionSlug}
              >
                <Link
                  to={'section/' + sectionSlug}
                  title={getSectionT(sectionSlug)('title')}
                  formFilled={formFilled}
                  disabled={sidebarDisabled}
                />
              </li>
            ))
          }
        </SectionSlugsConsumer>
        <li
          className={'nav-button' + (activePage === 'results' ? ' active' : '')}
        >
          <Link
            to={props.t('results:slug')}
            title={props.t('results:title')}
            formFilled={formFilled}
            disabled={sidebarDisabled}
          />
        </li>
      </ul>
    </nav>
  );
};

export default withT()(withRouter(Sidemenu));
