import React, { Component } from 'react';

import RatingInput from '../RatingInput';
import SectionTitleDesc from '../SectionTitleDesc';
import NotesInput from '../NotesInput';

import { getSectionT } from '../../i18n.js';
import { TProvider, withT } from '../../context/t.js';
import Text from '../Text';
import config from '../../config';

class Questions extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  getAnswerForHash(hash) {
    return this.props.answers[hash] || {};
  }

  defaultRating(rating) {
    if (typeof rating === 'number') {
      return rating;
    }
    return null;
  }

  renderQuestion(question) {
    const answer = this.getAnswerForHash(question.hash);

    return (
      <section key={question.hash}>
        <h4>
          <Text markdown={question.text} />
        </h4>
        <RatingInput
          value={this.defaultRating(answer.current)}
          label="current"
          onSelection={newRating =>
            this.props.onUpdate('current', question.hash, newRating)
          }
          type="now"
        />
        <RatingInput
          value={this.defaultRating(answer.target)}
          label="target"
          onSelection={newRating =>
            this.props.onUpdate('target', question.hash, newRating)
          }
          type="future"
        />
        {config.questions.inputNotes && (
          <NotesInput
            value={answer.note || ''}
            onChange={newNotes =>
              this.props.onUpdate('note', question.hash, newNotes || null)
            }
          />
        )}
      </section>
    );
  }

  render() {
    const t = getSectionT(this.props.sectionSlug);

    return (
      <TProvider value={t}>
        <div className="pagenav max-width">{this.props.prevNextLink}</div>
        <SectionTitleDesc
          title={<Text inline i18nKey={`title`} />}
          description={<Text i18nKey={`description`} />}
          icon={t(`icon`)}
        />
        <div id="question-section">
          <form>
            {t(`questions`, { returnObjects: true, defaultValue: [] })
              .length ? (
              t(`questions`, { returnObjects: true }).map(question =>
                this.renderQuestion(question)
              )
            ) : (
              <p>There are not yet any questions for this section</p>
            )}
            <section>
              <div className="pagenav max-width">
                <div>
                  <button type="button" onClick={this.props.onSave}>
                    Save
                  </button>
                </div>
                {this.props.prevNextLink}
              </div>
            </section>
          </form>
        </div>
      </TProvider>
    );
  }
}

export default withT('tool')(Questions);
