import React, { Component, Fragment, createRef } from 'react';
import styled from 'styled-components';

import InfoBox from './InfoBox';
import { withT } from '../context/t.js';
import { LayoutConsumer } from '../context/layout.js';

const InfoTextButton = styled.button`
  &&&:hover {
    background: none;
  }
`;

const getScrollY = () => window.scrollY || window.pageYOffset || 0;

class InfoButton extends Component {
  static openModal = null;

  state = {
    open: false,
  };

  buttonRef = createRef();

  close() {
    this.setState({ open: false });
  }

  toggleOpen = () => this.setState(oldState => {
    const newOpen = !oldState.open;

    if (newOpen) {
      if (InfoButton.openModal && InfoButton.openModal !== this) {
        InfoButton.openModal.close();
      }

      InfoButton.openModal = this;
    }

    return {
      open: newOpen
    };
  });

  componentWillUnmount() {
    if (InfoButton.openModal === this) {
      InfoButton.openModal = null;
    }
  }

  getModalOffset(mainRef) {
    if (!mainRef || !this.buttonRef.current) { return 0; }
    const mainTopDocumentY = mainRef.getBoundingClientRect().top + getScrollY();
    const buttonTopDocumentY = this.buttonRef.current.getBoundingClientRect().top + getScrollY();

    // The main ref is at the same height as the sidebar grid
    return buttonTopDocumentY - mainTopDocumentY;
  }

  render() {
    const child = React.Children.toArray(this.props.children)[0];
    const subject = child.trim();

    return (
      <Fragment>
        <LayoutConsumer>
          {({ mainRef, modalRef }) => (
            // Re-render the infobox with the latest y_position when the state becomes open: true
            this.state.open &&
            <InfoBox
              isOpen
              subject={subject}
              y_pos={this.getModalOffset(mainRef)}
              container={modalRef}
              onClose={this.toggleOpen}
            />
          )}
        </LayoutConsumer>

        <span className="sr-only">{subject}</span>
        <InfoTextButton
          className="info-button"
          type="button"
          onClick={this.toggleOpen}
          ref={this.buttonRef}
        >
          <span className="info-button-text" aria-hidden="true">
            {subject}
          </span>{' '}
          <i
            className="fas fa-info-circle"
            title={'Open definition of ' + subject}
            aria-hidden="true"
          />
          <span className="sr-only">Open definition of {subject}</span>
        </InfoTextButton>
      </Fragment>
    );
  }
}

export default withT('glossary')(InfoButton);
