const endSlash = /\/$/;
const startSlash = /^\//;

module.exports = (a = '', b = '') => {
  const aSlash = endSlash.test(a);
  const bSlash = startSlash.test(b);

  if (aSlash && bSlash) {
    return a.replace(endSlash, '') + b;
  }

  if (!aSlash && !bSlash) {
    return a + '/' + b;
  }

  return a + b;
};
