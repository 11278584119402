import React from 'react';

import Text from './Text.jsx';
import { withT } from '../context/t';

const Advertisement = props => (
  <a
    className="upsell"
    href={props.t('advertisement.href')}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="upsell--heading">
      <Text i18nKey="advertisement.question" />
    </div>
    <div className="summary">
      <Text i18nKey="advertisement.summary" />
    </div>
    <div className="linktext">
      <Text i18nKey="advertisement.action" />
    </div>
  </a>
);

export default withT('tool')(Advertisement);
