import React from 'react';

const SectionTitleDesc = props => {
  const { title, description, subtitle, icon } = props;

  return (
    <div>
      <h1>
        {icon && <i className={'hide-print fas ' + icon} />}
        {title}
      </h1>
      {/* There won't always be a subtitle or description. */}
      {description && <p className="intro hide-print">{description}</p>}
      {subtitle && <h3>{subtitle}</h3>}
    </div>
  );
};

export default SectionTitleDesc;
